import { graphql } from 'gatsby'
import React from 'react'

import Listing from './listing/listing'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'


interface Props {
  data: GatsbyTypes.ListingByPathQuery
  location: Location
}

const Template: React.FC<Props> = ({ data, location }: Props) => {
  const isPage = data.listing?.frontmatter?.layout != 'page'
  return (
    <div>
      <Layout location={location}>
        <Meta
          title={data.listing?.title || ''}
          site={data.site?.meta}
          description={data.listing?.description || ''}
        />
        <Listing
          data={data}
          location={location}
          options={{
            isIndex: false,
          }}
        />
      </Layout>
    </div>
  )
}

export default Template

export const ListingQuery = graphql`
  query ListingByPath($pagePath: String!) {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
    listing: listings(alias: {eq: $pagePath}) {
      path: alias
      report_type
      title
      client
      nid
      no
      summary
      updated_date
      issue_date
      client_name
      product_name
      sealed_document_id
    }
  }
`
